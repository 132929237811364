@import '../colors.scss';

@font-face {
  font-family: AvenirNextRegular;
  src: url('../../static/fonts/Avenir\ Light.ttf');
}

.menu {
  width: 250px;
  height: 100%;
  position: fixed;
  left: -250px; /* Initially hide the menu off-screen */
  top: 0;
  transition: left 0.5s ease; /* Smooth transition for the swing-out effect */

  padding: 1rem;

  background: $darkShade;

  &.open {
    left: 0; /* Bring the menu into view */
  }

  .equipment {
    display: flex;
    align-items: center;

    border-radius: 0.5rem;

    margin-bottom: 1rem;
    background: $lightShade;

    transform: translate(0, 0);
    cursor: pointer;

    .draggableEdge {
      display: grid;
      grid-template-columns: auto auto;
      padding: 0.5rem;

      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;

      background: #8a96c0;

      gap: 5px;

      margin-right: 0.5rem;

      .gridItem {
        width: 3px;
        height: 3px;

        background: $darkShade;
      }
    }

    .icon {
      width: 30px;
      height: 30px;

      margin-right: 0.5rem;

      background-size: cover;
    }

    .equipmentName {
      font-family: AvenirNextRegular;
    }
  }
}
