@import '../colors.scss';

.dashboardContainer {
  width: 100%;
  height: 100%;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  .chartWrapper {
    width: 80%;
  }

  .flowsheetMinimap {
    position: absolute;
    top: 2rem;
    right: 2rem;

    width: 100px;
    height: 100px;
    border-radius: 1rem;

    background-color: lightgray;
    border: black solid 0.1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.25);
  }
}

// TODO: Is the above still needed?
.dashboard {
  display: flex;
  width: 100%;
  min-height: 100%;

  .statsSection {
    background: $lightShade;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 3rem;

    .statsCard {
      width: 100%;
      background: white;
      border: solid 1px #b4b4b4;
      border-radius: 10px;

      .chartContainer {
        padding: 1.25rem;
      }

      padding-bottom: 1.25rem;

      margin-bottom: 4rem;
    }
  }
}
