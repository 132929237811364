@font-face {
  font-family: AvenirNextRegular;
  src: url("../../../static/fonts/Avenir\ Regular.ttf");
}

.selectorBar {
  display: flex;

  justify-content: space-between;
  align-items: center;

  padding: 1.25rem;

  .label {
    margin-right: 1rem;
    font-family: AvenirNextRegular;
    color: #6c6c6c;
  }

  .comparisonContainer {
    display: flex;
    align-items: center;

    .mainComparator {
      border: solid 1px #b4b4b4;
      border-radius: 6px;

      display: flex;
      align-items: center;

      padding: 0.5rem 1.25rem;

      cursor: pointer;
    }

    .secondaryContainer {
      .secondaryComparator {
        border: solid 1px #b4b4b4;
        border-radius: 6px;

        display: flex;
        align-items: center;

        padding: 0.5rem 1.25rem;

        cursor: pointer;
      }
    }

    .vs {
      margin: 0em 1rem;
    }
  }

  .gptContainer {
    border: solid 1px #b4b4b4;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5rem 1.25rem;

    cursor: pointer;
  }
}
