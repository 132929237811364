@import '../colors.scss';

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.smallSlider {
  background-color: $lightAccent;
}

.bigSlider {
  background-color: #ccc;
}

.slider {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 0.4s;

  &.rounded {
    border-radius: 34px;
  }

  &.rounded:before {
    border-radius: 50%;
  }
}

.smallSlider:before {
  position: absolute;
  content: '';
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: $lightShade;

  transition: 0.4s;
}

.bigSlider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: $lightShade;

  transition: 0.4s;
}

input:checked + .smallSlider {
  background-color: $darkShade;
}

input:checked + .bigSlider {
  background-color: $brandBlue;
}

input:checked + .bigSlider:before {
  transform: translateX(26px);
}

input:checked + .smallSlider:before {
  transform: translateX(13px);
}
