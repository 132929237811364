.tankIcon {
  width: 50px;
  height: 50px;
  background-image: url('https://untanglify-images.s3.amazonaws.com/tank.svg');
  background-size: cover;
}

.category {
  border: black solid 1px;
  border-radius: 0.5rem;

  padding: 0.25rem 1rem 0.25rem 1rem;
  margin-bottom: 1rem;

  font-weight: 600;
}
