@import '../../colors.scss';

@font-face {
  font-family: AvenirNext;
  src: url('../../../static/fonts/Avenir\ Regular.ttf');
}

.bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $lightShade;
  border-top: 1px solid black;

  padding: 1rem 2rem;

  .simulationWrapper {
    display: flex;
    align-items: center;

    position: relative;

    .settingsContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      font-family: AvenirNext;

      .inputWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .label {
          margin-bottom: 0.25rem;
        }

        .valueWrapper {
          display: flex;
          align-items: center;

          border-radius: 0.5rem;

          border: 1px solid $lightGreyBorder;
          background: white;

          .icon {
            margin: 0 1rem;
          }

          .unit {
            background: $darkAccent;
            padding: 0.5rem 1rem;

            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;

            outline: none;

            color: $lightShade;
          }
        }
      }
    }

    .trash {
      margin-right: 1rem;
      cursor: pointer;

      color: $darkShade;
    }

    .settingsIcon {
      margin-right: 1rem;

      cursor: pointer;

      color: $darkShade;
    }
  }
}
