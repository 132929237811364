@import '../../colors.scss';

@font-face {
  font-family: AvenirNextRegular;
  src: url('../../../static/fonts/Avenir\ Black.ttf');
}

.signalContainer {
  position: absolute;

  display: flex;
  align-items: center;

  font-family: AvenirNextRegular;
  color: $darkAccent;

  top: 1rem;
  right: -75%;

  .signalText {
    font-size: 0.75rem;
    margin-left: 1rem;
  }
}
