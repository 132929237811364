.statusCard {
  --successBorder: #bcf4d5;
  --successShade: #f5fef8;
  --successDarkAccent: #509b74;
  --successLightAccent: #74a988;

  --warningDarkAccent: #a46237;
  --warningLightAccent: #c77f27;
  --warningShade: #fefdee;
  --warningBorder: #ffeeac;

  --errorShade: #fefbfa;
  --errorDarkAccent: #d72216;
  --errorLightAccent: #f8867e;
  --errorBorder: #ffd1cf;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  border-radius: 0.5rem;

  padding: 1rem;

  .icon {
    margin-right: 1rem;
  }

  .exit {
    margin-left: 1rem;
    cursor: pointer;
  }

  .messageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0;
    margin: 0;

    .status {
      padding: 0;
      margin: 0;
    }

    .message {
      padding: 0;
      margin: 0;
    }
    .learnMore {
      padding: 0;
      margin-top: 1rem;
    }
  }
}
