@font-face {
  font-family: AvenirNextBlack;
  src: url("../../../static/fonts/Avenir\ Black.ttf");
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.25rem;

  border-bottom: solid 1px #b4b4b4;

  .iconContainer {
    display: flex;
    align-items: center;

    .icon {
      width: 44.5px;
      height: 40px;
      background-size: cover;
      margin-right: 2.5rem;
    }
    .graphTitle {
      font-size: 1.5rem;
      font-family: AvenirNextBlack;
      color: #6c6c6c;
    }
  }
  .dateContainer {
    display: flex;
    align-items: center;

    color: #b4b4b4;

    .startDate {
      font-size: 1.25rem;
    }
    .arrowIcon {
      background-image: url("../../../static/common/dateArrow.svg");
      width: 30px;
      height: 16px;
      background-size: cover;
      margin: 0em 2rem;
    }
    .endDate {
      font-size: 1.25rem;
    }
  }
}
