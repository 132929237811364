@font-face {
  font-family: AvenirNextHeavy;
  src: url('../../../static/fonts/Avenir\ Heavy.ttf');
}

.container {
  border: 1px solid #bdbdbd;
  border-radius: 15px;
  min-width: 300px;

  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.25);
  background: white;

  .containerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;

    .labelWrapper {
      display: flex;
      align-items: center;

      .equipmentIcon {
        width: 50px;
        height: 50px;
        background-image: url('https://untanglify-images.s3.amazonaws.com/pumpIcon.svg');
        background-size: cover;
      }

      .containerLabel {
        font-family: AvenirNextHeavy;
        font-size: 1.25rem;
        margin: 0rem 1rem;
        outline: none;
      }
    }

    .containerIcon {
      color: #7e7e7e;
    }
  }

  .dropdownSection {
    border-top: 1px solid #bdbdbd;

    .middleSection {
      padding: 0.5rem;

      .parameter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        .paramText {
          font-weight: 600;
          font-size: 0.75rem;
          margin-right: 0.75rem;
          font-family: AvenirNextHeavy;
        }
        .inputWrapper {
          display: flex;
          .input {
            border: 1px solid #bdbdbd;
            border-radius: 5px;
            max-width: 4rem;
            font-size: 0.75rem;
          }

          .select {
            outline: none;
            font-family: AvenirNextHeavy;
            font-size: 0.75rem;
            -webkit-user-drag: none;

            .option {
              -webkit-user-drag: none;
            }
          }
        }
      }
    }

    .bottomContainer {
      display: flex;
      flex-direction: column;

      .topBar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        background: rgb(248, 248, 248);

        .toggleSection {
          padding: 0.5rem 0.25rem;
          display: flex;
          align-items: center;

          .toggleText {
            font-family: AvenirNextHeavy;
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
            color: darkgray;
          }

          .active {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            color: black;
          }
        }
      }
      .results {
        height: 100px;
      }
    }
  }
}
