$brandBlue: #72c7f0;
$darkShade: #233d7c;
$lightShade: #f5f4ef;
$lightAccent: #808bb1;
$darkAccent: #6d6899;
$lightGreyBorder: #b4b4b4;

$successBorder: #bcf4d5;
$successShade: #f5fef8;
$successDarkAccent: #509b74;
$successLightAccent: #74a988;

$warningDarkAccent: #a46237;
$warningLightAccent: #c77f27;
$warningShade: #fefdee;
$warningBorder: #ffeeac;

$errorShade: #fefbfa;
$errorDarkAccent: #d72216;
$errorLightAccent: #f8867e;
$errorBorder: #ffd1cf;
