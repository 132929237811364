@import '../../colors.scss';

@font-face {
  font-family: AvenirNextBlack;
  src: url('../../../static/fonts/Avenir\ Heavy.ttf');
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $darkShade;
  padding: 0.75rem 1.25rem;

  border-radius: 10px;

  .text {
    color: $lightShade;
    font-family: AvenirNextBlack;

    font-size: 1rem;
    letter-spacing: 1.5px;
  }

  .icon {
    color: $lightShade;
    margin-right: 1rem;
  }
}

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  margin-right: 0.5rem;
}
.ldsEllipsis div {
  position: absolute;
  top: 7px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
  left: 2px;
  animation: lds-ellipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 2px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 14px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(6px, 0);
  }
}
