@import '../colors.scss';

.box {
  position: absolute;

  background-color: $lightShade;
  border: black solid 0.1rem;
  border-radius: 1rem;
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.25);

  padding: 1rem;

  top: -150%;
  left: -40%;

  .chevron {
    width: 20px;
    height: 20px;

    background: $lightShade;
    border-bottom: black solid 0.1rem;
    border-right: black solid 0.1rem;

    transform: rotate(45deg);

    position: absolute;
    bottom: -11px;
    right: 15px;
  }
}
