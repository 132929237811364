@font-face {
  font-family: AvenirNextRegular;
  src: url('../../../static/fonts/Avenir\ Regular.ttf');
}

@font-face {
  font-family: AvenirNextBlack;
  src: url('../../../static/fonts/Avenir\ Black.ttf');
}

.table {
  font-family: AvenirNextRegular;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  .body {
    .row {
      cursor: pointer;

      .col {
        &.hoverable {
          transition: background-color 0.25s ease;
          &:hover {
            background-color: #e2f1fd;
          }

          .active-label {
            font-family: AvenirNextBlack;
          }
        }
      }

      .middleCol {
        border-left: solid 1px #b4b4b4;
        border-right: solid 1px #b4b4b4;
      }

      .item {
        display: flex;

        justify-content: space-between;
        align-items: center;

        padding: 0.5rem 2rem;

        .label {
        }

        .value {
          margin-left: 2rem;
          font-family: AvenirNextBlack;
        }
      }
    }
  }
}
