@import '../../colors.scss';

.canvas {
  position: relative;

  .dashboardMinimap {
    position: absolute;
    top: 2rem;
    right: 2rem;

    width: 100px;
    height: 100px;
    border-radius: 1rem;

    background-color: $lightShade;
    border: black solid 0.1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.25);
  }
}
