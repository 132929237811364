@import '../../colors.scss';

@font-face {
  font-family: AvenirBook;
  src: url('../../../static/fonts/Avenir\ Book.ttf');
}

.signalSettingsContainer {
  position: absolute;

  background: $lightShade;

  border: black solid 0.1rem;
  border-radius: 1rem;
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);

  padding: 1rem;

  .titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 1rem 1rem 0;

    .title {
      font-size: 1.15rem;
      margin-right: 1rem;
      font-family: AvenirBook;
    }

    .icon {
      cursor: pointer;
    }
  }

  .signalBox {
    font-family: AvenirBook;

    .signalDescription {
      .controlContainer {
        .controlTitle {
          margin: 1rem 0rem;

          padding-bottom: 0.5rem;

          border-bottom: 1px solid $lightGreyBorder;
        }

        .configurables {
          .property {
            display: flex;
            flex-direction: column;

            .label {
              margin: 0.5rem 0rem;
            }
          }
        }
      }
    }
  }
}
