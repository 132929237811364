@font-face {
  font-family: AvenirNextRegular;
  src: url('../../../static/fonts/Avenir\ Regular.ttf');
}

@import '../../colors.scss';

.container {
  position: fixed;

  bottom: 2rem;
  left: 2rem;

  // background-color: rgba(255, 255, 255, 0.45);
  // backdrop-filter: blur(5px);

  background: $lightAccent;
  padding: 0.5rem 1rem;
  border-radius: 2rem;

  border: solid 1px black;

  font-family: AvenirNextRegular;

  color: $lightShade;

  .select {
    background: none;
    margin-right: 0.5rem;

    outline: none;
    border: none;

    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
