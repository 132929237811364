@import '../../colors.scss';

@font-face {
  font-family: AvenirBook;
  src: url('../../../static/fonts/Avenir\ Book.ttf');
}

.connectionSettingsContainer {
  position: absolute;

  background: $lightShade;

  border: black solid 0.1rem;
  border-radius: 1rem;
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);

  padding: 1rem;

  .titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      margin: 0 1rem 1rem 0;
      font-size: 1.15rem;
      font-family: AvenirBook;
    }

    .icon {
      cursor: pointer;
    }
  }
}

.middleSection {
  .parameter {
    .paramText {
      font-family: AvenirBook;
      margin: 0.25rem 0rem;
    }
    .inputWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-radius: 0.5rem;
      border: 1px solid $lightGreyBorder;
      background: white;

      .input {
        padding: 0 1rem;

        &:focus {
          outline: none;
        }
      }

      .select {
        background: $darkAccent;
        padding: 0.5rem 1rem;

        border-radius: 0.5rem;

        outline: none;

        color: $lightShade;

        min-width: 10%;
      }
    }
  }
}
